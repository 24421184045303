import axiosApi from '../../kits/axiosApi';

// initial state
const state = {
    is_loading: false,
    sid: window.localStorage.getItem('sid') || '',
    role: window.localStorage.getItem('role') || '',
    sname: window.localStorage.getItem('sname') || '',
    slogo: window.localStorage.getItem('slogo') || '',
    token: window.localStorage.getItem('token') || '',
    timezone: window.localStorage.getItem('timezone') || '',
    user_name: window.localStorage.getItem('user_name') || '',
    country: window.localStorage.getItem('country') || '',
    is_mobile: document.body.clientWidth < 768,
    version: '0.2.1',
    build_number: 7,
};

// getters
const getters = {
    get_if_loading(state) {
        return state.is_loading;
    }
};

// actions
const actions = {
    initData({ rootState }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/test", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                    } else {
                        console.log(res.data.data);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_GLOBAL_DAT(state, _data) {
        state.sid = _data.data.sid;
        state.role = _data.data.role;
        state.sname = _data.data.sname;
        state.slogo = _data.data.slogo;
        state.token = _data.data.token;
        state.timezone = _data.data.timezone;
        state.user_name = _data.data.user_name;
        state.country = _data.data.country;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
